import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import NavBarDesktop from "./NavBarDesktop";
import NavBarMobile from "./NavBarMobile";

function Nav() {
  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    if (window.scrollY > 30) setAtTop(false);
    else setAtTop(true);
  };

  if (isMobile) return <NavBarMobile isMobile={isMobile} top={atTop} />;
  return <NavBarDesktop isMobile={isMobile} top={atTop} />;
}

export default Nav;
