import React, { useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { isMobile } from "react-device-detect";
import ContactFormStyle from "./styles/ContactFormStyle";
import JumboText from "./JumboText";
import Error from "./ErrorMessage";
import Success from "./SuccessMessage";

const CONTACT_BUILDER = gql`
  mutation requestContact($customerData: RequestCreateInput!) {
    requestContact(data: $customerData) {
      customer_id
    }
  }
`;

const successMessage =
  "Success! Thank you for reaching out to Third Day Builders! You should receive a confirmation email shortly.";

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  streetAddress: "",
  city: "",
  state: "WV",
  zipCode: "",
  chkCustom: false,
  chkAddition: false,
  chkRemodel: false,
  budget: "",
  description: "",
};

function ContactForm() {
  const [
    {
      firstName,
      lastName,
      phone,
      email,
      streetAddress,
      city,
      state,
      zipCode,
      chkCustom,
      chkAddition,
      chkRemodel,
      budget,
      description,
    },
    setState,
  ] = useState(initialState);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, []);

  const [contactBuilder, { loading, error, called }] =
    useMutation(CONTACT_BUILDER);

  const saveState = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const saveBoolState = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const submitFormWithRecaptcha = async () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: "submit",
        })
        .then(async (recaptchaToken) => {
          await contactBuilder({
            variables: {
              customerData: {
                firstName,
                lastName,
                email,
                phone,
                streetAddress,
                city,
                state,
                zipCode,
                chkCustom,
                chkRemodel,
                chkAddition,
                description,
                budget,
                recaptchaToken,
              },
            },
          });
        });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitFormWithRecaptcha();
    window.scrollTo(0, 0);
    setState(initialState);
  };

  return (
    <div>
      <JumboText
        title="Contact Third Day Builders"
        body="Let's begin building your dream home"
      />
      <Error error={error} />
      {!error && !loading && called && (
        <Success successMessage={successMessage} />
      )}
      <ContactFormStyle
        method="post"
        onSubmit={handleSubmit}
        isMobile={isMobile}
      >
        <fieldset disabled={loading} aria-busy={loading}>
          <label htmlFor="firstName">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={saveState}
              required
            />
          </label>
          <label htmlFor="lastName">
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={saveState}
              required
            />
          </label>
          <label htmlFor="streetAddress">
            <input
              type="text"
              name="streetAddress"
              placeholder="Street Address"
              value={streetAddress}
              onChange={saveState}
              required
            />
          </label>
          <label htmlFor="city">
            <input
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={saveState}
              required
            />
          </label>
          <div id="zipState">
            <label htmlFor="state">
              <select name="state" onChange={saveState}>
                <option value={state}>West Virginia</option>
              </select>
            </label>
            <label htmlFor="zipCode">
              <input
                type="text"
                name="zipCode"
                placeholder="Zip Code"
                id="contactZipCode"
                value={zipCode}
                onChange={saveState}
                required
              />
            </label>
          </div>
          <label htmlFor="email">
            <input
              type="email"
              name="email"
              placeholder="Email"
              id="contactEmail"
              value={email}
              onChange={saveState}
              required
            />
          </label>
          <label htmlFor="phone">
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={phone}
              onChange={saveState}
              required
            />
          </label>
        </fieldset>
        <fieldset disabled={loading} aria-busy={loading}>
          <p>Type of Work (Select all that apply):</p>
          <div className="contactCheckboxes">
            <label htmlFor="chkCustom">
              <input
                type="checkbox"
                name="chkCustom"
                value="custom"
                checked={chkCustom}
                onChange={saveBoolState}
              />
              Build a Custom Home
            </label>
          </div>
          <div className="contactCheckboxes">
            <label htmlFor="chkRemodel">
              <input
                type="checkbox"
                name="chkRemodel"
                value="remodel"
                checked={chkRemodel}
                onChange={saveBoolState}
              />
              Remodel a Home
            </label>
          </div>
          <div className="contactCheckboxes">
            <label htmlFor="chkAddition">
              <input
                type="checkbox"
                name="chkAddition"
                value="addition"
                checked={chkAddition}
                onChange={saveBoolState}
              />
              Add an Addition to a Home
            </label>
          </div>
          <label htmlFor="budget">
            <input
              type="number"
              name="budget"
              placeholder="Projected budget for project"
              value={budget}
              onChange={saveState}
              required
            />
          </label>
          <label htmlFor="description">
            <textarea
              name="description"
              placeholder="Description of the project"
              id="contactTextArea"
              value={description}
              onChange={saveState}
              required
            />
          </label>
        </fieldset>
        <div disabled={loading} aria-busy={loading} id="submitField">
          <div
            className="g-recaptcha"
            data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            data-size="invisible"
          ></div>
          <button type="submit" className="contactButton">
            Submit
          </button>
        </div>
      </ContactFormStyle>
    </div>
  );
}

export default ContactForm;
