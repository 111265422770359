import React from "react";
import { Link } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import OffScreenMenuStyles from "./styles/OffScreenMenuStyle";

import { isMenuOpen } from "../cache";

const OffScreenMenu = () => {
  const menuOpen = useReactiveVar(isMenuOpen);

  return (
    <OffScreenMenuStyles open={menuOpen}>
      <div>
        <Link
          to="/"
          tabIndex={0}
          onClick={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
        >
          Home
        </Link>
        <Link
          to="/about"
          tabIndex={0}
          onClick={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
        >
          About
        </Link>
        <Link
          to="/galleries"
          tabIndex={0}
          onClick={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
        >
          Gallery
        </Link>
        <Link
          to="/testimonials"
          tabIndex={0}
          onClick={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
        >
          Testimonials
        </Link>
        <Link
          to="/contact"
          tabIndex={0}
          onClick={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
        >
          Contact
        </Link>
        <br />
        <hr />
        <br />
        <p>Have a project in mind?</p>
        <p>We can&apos;t wait to hear about it!</p>
        <p>kevin.weaver@thirddaybuilders.com</p>
        <p>(304) 283-3863</p>
        <p>
          <img src={process.env.PUBLIC_URL + "/WhiteLogo.svg"} alt="Logo" />
        </p>
      </div>
    </OffScreenMenuStyles>
  );
};

export default OffScreenMenu;
