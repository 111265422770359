import { useSearchParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import GalleryAllPhotos from "../components/GalleryAllPhotos";

const GET_GALLERY_BY_ID = gql`
  query GetGalleriesByType($searchTerm: Int!) {
    gallery(gallery_id: $searchTerm) {
      gallery_id
      galNumberItems
      galPath
      galType
    }
  }
`;

export default function Gallery() {
  let [searchParams] = useSearchParams();
  const { loading, error, data } = useQuery(GET_GALLERY_BY_ID, {
    variables: { searchTerm: parseInt(searchParams.get("id")) },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return <GalleryAllPhotos gallery={data.gallery} />;
}
