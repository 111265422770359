import React from "react";
import { Routes, Route } from "react-router-dom";

import Nav from "./components/Nav";
import Main from "./routes/Main";
import Error from "./routes/Error";
import About from "./routes/About";
import Galleries from "./routes/Galleries";
import Gallery from "./routes/Gallery";
import Testimonials from "./routes/Testimonials";
import Contact from "./routes/Contact";
import Footer from "./components/Footer";
import GlobalStyle from "./components/styles/GlobalStyle";

function App() {
  return (
    <>
      <GlobalStyle />
      <Nav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/galleries" element={<Galleries />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
