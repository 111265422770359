import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import GalleryStyle from "./styles/GalleryStyle";
import GalleryOverlay from "./GalleryOverlay";
import GalleryImage from "./GalleryImage";

const basePath = process.env.REACT_APP_S3_BASE;

function SoloGallery({ gallery, isMobile }) {
  const [isHovering, setIsHovering] = useState(false);
  const imgSrc = basePath + gallery.galPath;

  const handleGalleryHover = () => {
    setIsHovering(!isHovering);
  };

  if (gallery.galNumberItems > 1) {
    return (
      <GalleryStyle isMobile={isMobile}>
        <FontAwesomeIcon className="picture-icon" icon={faClone} size="3x" />
        <GalleryOverlay
          hovered={isHovering}
          numItems={gallery.galNumberItems}
        />
        <GalleryImage
          gallery={gallery.gallery_id}
          basePath={imgSrc}
          onGalleryHover={handleGalleryHover}
        />
      </GalleryStyle>
    );
  }
  return (
    <GalleryStyle isMobile={isMobile}>
      <GalleryOverlay hovered={isHovering} numItems={gallery.galNumberItems} />
      <GalleryImage
        gallery={gallery.gallery_id}
        basePath={imgSrc}
        onGalleryHover={handleGalleryHover}
      />
    </GalleryStyle>
  );
}

SoloGallery.propTypes = {
  gallery: PropTypes.shape({
    gallery_id: PropTypes.number.isRequired,
    galNumberItems: PropTypes.number.isRequired,
    galPath: PropTypes.string.isRequired,
    galType: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default SoloGallery;
