import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const imgSrc = `${process.env.REACT_APP_S3_BASE}slideshow/`;
const homeNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const SlideshowStyles = styled.div`
  height: ${(props) => (props.isMobile ? "30vh" : "50vh")};
  img {
    width: 100%;
    height: 100%;
    object-fit: ${(props) => (props.isMobile ? "contain" : "cover")};
  }
`;

function Slideshow({ isMobile }) {
  const [slideNum, setSlideNum] = useState(0);
  const srcText = `${imgSrc}${homeNumbers[slideNum]}-480.jpg`;
  const srcSetText = `${imgSrc}${homeNumbers[slideNum]}-480.jpg 480w, ${imgSrc}${homeNumbers[slideNum]}-767.jpg 767w, ${imgSrc}${homeNumbers[slideNum]}-1024.jpg 1024w, ${imgSrc}${homeNumbers[slideNum]}-1280.jpg 1280w, ${imgSrc}${homeNumbers[slideNum]}-1280.jpg 1300w`;
  let interval = 0;

  useEffect(() => {
    interval = setInterval(handleInterval, 5000);
    return function cleanup() {
      clearInterval(interval);
    };
  });

  const handleInterval = () => {
    const pos = slideNum === homeNumbers.length - 1 ? 0 : slideNum + 1;
    setSlideNum(pos);
  };

  return (
    <SlideshowStyles isMobile={isMobile}>
      <img
        srcSet={srcSetText}
        sizes="100%"
        src={srcText}
        alt="Home Slideshow Screen"
      />
    </SlideshowStyles>
  );
}

Slideshow.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Slideshow;
