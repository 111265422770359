import React from "react";
import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";
import OffScreenMenu from "./OffScreenMenu";
import HamburgerMenuStyle from "./styles/HamburgerMenuStyle";
import { isMenuOpen } from "../cache";

const HamburgerMenu = ({ top }) => {
  const menuOpen = useReactiveVar(isMenuOpen);

  return (
    <HamburgerMenuStyle top={top}>
      <input
        type="checkbox"
        checked={menuOpen}
        onChange={() => (menuOpen ? isMenuOpen(false) : isMenuOpen(true))}
      />
      <div>
        <span />
        <span />
        <span />
      </div>
      <OffScreenMenu />
    </HamburgerMenuStyle>
  );
};

HamburgerMenu.propTypes = {
  top: PropTypes.bool.isRequired,
};

export default HamburgerMenu;
