import React from "react";
import { useQuery, gql } from "@apollo/client";
import Testimonial from "./Testimonial";
import JumboText from "./JumboText";

const GET_TESTIMONIALS = gql`
  query GetTestimonials {
    testimonials(orderBy: { testimonial_id: desc }) {
      testimonial_id
      body
      headline
      customer {
        customer_id
        firstName
      }
      gallery {
        gallery_id
        galPath
      }
    }
  }
`;

const Testimonials = () => {
  const { loading, error, data } = useQuery(GET_TESTIMONIALS);

  if (loading)
    return (
      <div>
        <JumboText
          title="Customer Testimonials"
          body="See what customers think of Third Day Builders"
        />
        <div style={{ textAlign: "center" }}>
          <p>Loading...</p>
        </div>
      </div>
    );

  if (error)
    return (
      <div>
        <JumboText
          title="Customer Testimonials"
          body="See what customers think of Third Day Builders"
        />
        <div style={{ textAlign: "center" }}>
          <p>Error: {error.message}</p>
        </div>
      </div>
    );

  return (
    <div>
      <JumboText
        title="Customer Testimonials"
        body="See what customers think of Third Day Builders"
      />
      <div>
        {data.testimonials.map((testimonial) => (
          <Testimonial
            testimonial={testimonial}
            key={testimonial.testimonial_id}
          />
        ))}
      </div>
      );
    </div>
  );
};

export default Testimonials;
