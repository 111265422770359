import React, { useState } from "react";
import PropTypes from "prop-types";

const swipeThresholdX = 50;
const swipeThresholdY = 75;

function GalleryAllMainImage({ url, position, nextPhoto, prevPhoto }) {
  const [swipeX, setSwipeX] = useState(0);
  const [swipeY, setSwipeY] = useState(0);

  const handleTouchStart = (event) => {
    setSwipeX(event.changedTouches[0].pageX);
    setSwipeY(event.changedTouches[0].pageY);
  };

  const handleTouchMove = (event) => {
    const distanceX = event.changedTouches[0].pageX - swipeX;
    if (Math.abs(distanceX) > swipeThresholdX / 2) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  const handleTouchEnd = (event) => {
    const distanceX = event.changedTouches[0].pageX - swipeX;
    const distanceY = event.changedTouches[0].pageY - swipeY;

    if (distanceX > swipeThresholdX && Math.abs(distanceY) <= swipeThresholdY) {
      prevPhoto();
    } else if (
      distanceX < -swipeThresholdX &&
      Math.abs(distanceY) <= swipeThresholdY
    ) {
      nextPhoto();
    }
  };

  return (
    <div>
      <img
        sizes="100%"
        src={`${url}${position}-1024.jpg`}
        alt="Main Gallery"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      />
    </div>
  );
}

GalleryAllMainImage.propTypes = {
  url: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  nextPhoto: PropTypes.func,
  prevPhoto: PropTypes.func,
};

export default GalleryAllMainImage;
