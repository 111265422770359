import React from "react";
import ReactDOM from "react-dom/client";
import { HttpLink, ApolloClient, ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { cache } from "./cache";

import App from "./App";
import ScrollToTop from "./helpers/ScrollToTop";

let client;
if (process.env.REACT_APP_IS_PROD === "true") {
  const backendLink = new HttpLink({uri: "/graphql"});
  client = new ApolloClient({
    cache: cache,
    link: backendLink,
  });
} else if (process.env.REACT_APP_IS_PROD === "false") {

}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
