import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import GalleryPickerStyle from "./styles/GalleryPickerStyle";
import GalleryPickerItem from "./GalleryPickerItem";

function GalleryPicker({ url, max, onPickerSelection, currPhoto }) {
  const scrollDiv = React.createRef();

  useEffect(() => {
    if (isMobile) scrollDiv.current.scrollLeft = getPickerScollPos();
    else scrollDiv.current.scrollTop = getPickerScollPos();
  });

  const getPickerScollPos = () => {
    let scrollPos;
    const { scrollWidth, scrollHeight } = scrollDiv.current;
    if (currPhoto > 1) {
      scrollPos = isMobile
        ? (scrollWidth / max) * (currPhoto - 1)
        : (scrollHeight / max) * (currPhoto - 1);
    }
    if (currPhoto + 1 >= max) {
      scrollPos = 0;
    }
    if (currPhoto === max - 1) {
      scrollPos = isMobile
        ? (scrollWidth / max) * (max - 2)
        : (scrollHeight / max) * (max - 2);
    }
    return scrollPos;
  };

  return (
    <GalleryPickerStyle ref={scrollDiv} isMobile={isMobile}>
      {[...Array(max)].map((pos, i) => (
        <GalleryPickerItem
          key={i + 1}
          url={url}
          photo={i}
          onPickerSelection={onPickerSelection}
          currPhoto={currPhoto}
        />
      ))}
    </GalleryPickerStyle>
  );
}

GalleryPicker.propTypes = {
  url: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  onPickerSelection: PropTypes.func.isRequired,
  currPhoto: PropTypes.number.isRequired,
};

export default GalleryPicker;
