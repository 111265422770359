import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import GalleryAllPhotosStyle from "./styles/GalleryAllPhotosStyle";
import GalleryAllMainImage from "./GalleryAllMainImage";
import GalleryPicker from "./GalleryPicker";

const basePath = process.env.REACT_APP_S3_BASE;

const SpacingDiv = styled.div`
  height: 20vh;
  background-color: ${(props) => (props.top ? "#501414" : "transparent")};
  transition: all 0.3s;
  margin-bottom: 3vh;
`;

function setBasePhotoURL(gallery) {
  return `${basePath + gallery.galPath}`;
}

function GalleryAllPhotos({ gallery }) {
  const [mainUrl] = useState(setBasePhotoURL(gallery));
  const [activePhoto, setActivePhoto] = useState(0);
  const [totalPhotos] = useState(gallery.galNumberItems);
  const [isTopPage, setIsTopPage] = useState(true);
  const focusDiv = React.createRef();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    focusDiv.current.focus();
    window.scrollTo(0, window.innerHeight * 0.12);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const nextPhoto = () => {
    const pos = activePhoto + 1;
    if (pos >= totalPhotos) {
      setActivePhoto(0);
    } else {
      setActivePhoto(activePhoto + 1);
    }
  };

  const prevPhoto = () => {
    const pos = activePhoto - 1;
    if (pos < 0) {
      setActivePhoto(totalPhotos - 1);
    } else {
      setActivePhoto(activePhoto - 1);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 30) setIsTopPage(false);
    else setIsTopPage(true);
  };

  const handleArrows = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }

    if (e.keyCode === 37 || e.keyCode === 38) {
      prevPhoto();
    } else if (e.keyCode === 39 || e.keyCode === 40) {
      nextPhoto();
    }
  };

  const handlePickerSelection = (selection) => {
    setActivePhoto(selection);
  };

  if (isMobile) {
    return (
      <div>
        <SpacingDiv top={isTopPage} />
        <GalleryAllPhotosStyle
          isMobile={isMobile}
          tabIndex={-1}
          ref={focusDiv}
          onKeyDown={handleArrows}
        >
          <GalleryAllMainImage
            url={mainUrl}
            position={activePhoto}
            nextPhoto={nextPhoto}
            prevPhoto={prevPhoto}
          />
          <GalleryPicker
            url={mainUrl}
            max={totalPhotos}
            onPickerSelection={handlePickerSelection}
            currPhoto={activePhoto}
          />
        </GalleryAllPhotosStyle>
      </div>
    );
  }
  return (
    <div>
      <SpacingDiv top={isTopPage} />
      <GalleryAllPhotosStyle
        isMobile={isMobile}
        tabIndex={-1}
        ref={focusDiv}
        onKeyDown={handleArrows}
      >
        <GalleryAllMainImage url={mainUrl} position={activePhoto} />
        <GalleryPicker
          url={mainUrl}
          max={totalPhotos}
          onPickerSelection={handlePickerSelection}
          currPhoto={activePhoto}
        />
      </GalleryAllPhotosStyle>
    </div>
  );
}

GalleryAllPhotos.propTypes = {
  gallery: PropTypes.shape({
    gallery_id: PropTypes.number.isRequired,
    galNumberItems: PropTypes.number.isRequired,
    galPath: PropTypes.string.isRequired,
    galType: PropTypes.string.isRequired,
  }).isRequired,
};

export default GalleryAllPhotos;
