import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import JumboText from "./JumboText";
import GalleryDropDown from "./GalleryDropDown";
import SoloGallery from "./SoloGallery";

const Center = styled.div`
  text-align: center;
`;

const GalleryList = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isMobile ? "1fr" : "1fr 1fr")};
  padding-top: 5vh;
  padding-right: 2vw;
  padding-left: 2vw;
  grid-gap: 2vw;
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
`;

const GET_GALLERIES = gql`
  query GetGalleries {
    galleries(orderBy: { galRank: asc }) {
      gallery_id
      galNumberItems
      galPath
      galType
    }
  }
`;

const GET_GALLERIES_BY_TYPE = gql`
  query GetGalleriesByType($searchTerm: String!) {
    galleries(galType: $searchTerm, orderBy: { galRank: asc }) {
      gallery_id
      galNumberItems
      galPath
      galType
    }
  }
`;

const dropDownValues = ["all", "home", "addition", "remodel"];

function AllGalleries() {
  const [selection, setSelection] = useState("all");
  const [query, setQuery] = useState(GET_GALLERIES);
  const { loading, error, data } = useQuery(query, {
    variables: { searchTerm: selection },
  });

  const handleSelection = (e) => {
    const pick = e.target.value;
    setSelection(pick);

    if (pick === "all") {
      setQuery(GET_GALLERIES);
    } else {
      setQuery(GET_GALLERIES_BY_TYPE);
    }
  };

  if (loading)
    return (
      <Center>
        <JumboText
          title="Gallery"
          body="View the expert craftsmanship provided by Third Day Builders"
        />
        <p>Loading...</p>
      </Center>
    );

  if (error)
    return (
      <Center>
        <JumboText
          title="Gallery"
          body="View the expert craftsmanship provided by Third Day Builders"
        />
        <p>Error...</p>
      </Center>
    );

  return (
    <Center>
      <JumboText
        title="Gallery"
        body="View the expert craftsmanship provided by Third Day Builders"
      />
      <GalleryDropDown
        isMobile={isMobile}
        dropDownChange={handleSelection}
        values={dropDownValues}
        selectedOption={selection}
      />
      <GalleryList isMobile={isMobile}>
        {data.galleries.map((gallery) => (
          <SoloGallery
            isMobile={isMobile}
            gallery={gallery}
            key={gallery.gallery_id}
          />
        ))}
      </GalleryList>
    </Center>
  );
}

export default AllGalleries;
