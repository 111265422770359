import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import FooterStyle from "./styles/FooterStyle";
import Logo from "./Logo";

const Footer = () => (
  <FooterStyle mobile={isMobile}>
    <hr />
    <div className="upper-wrapper">
      <div className="contact">
        <h2>Contact Us</h2>
        <div className="logo">
          <div>
            <Logo
              imgSrc={process.env.PUBLIC_URL + "/MaroonLogo.svg"}
              pad={false}
              isMobile={isMobile}
            />
          </div>
          <div>
            <p>
              <FontAwesomeIcon icon={faEnvelope} size="1x" />
              &nbsp;kevin.weaver@thirddaybuilders.com
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} size="1x" />
              &nbsp;(304) 283-3863
            </p>
          </div>
        </div>
      </div>
      <div className="navigation">
        <h2>Navigation</h2>
        <div className="navigation-submenu">
          <div>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/about">About</Link>
            </div>
            <div>
              <Link to="/galleries">Gallery</Link>
            </div>
          </div>
          <div>
            <div>
              <Link to="/testimonials">Testimonials</Link>
            </div>
            <div>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div className="lower-wrapper">
      <div>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/terms">Terms of Use</Link>
        <Link to="/sitemap">Sitemap</Link>
        <p>
          &copy; 2017-
          {new Date().getFullYear()} &nbsp; Third Day Builders, LLC
        </p>
      </div>
    </div>
  </FooterStyle>
);

export default Footer;
