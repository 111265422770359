import React from "react";
import { useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";
import HamburgerMenu from "./HamburgerMenu";
import NavBarMobileStyle from "./styles/NavBarMobileStyle";
import LogoNav from "./LogoNav";

import { isMenuOpen } from "../cache";

const NavBarMobile = ({ top, isMobile }) => {
  const pad = true;
  const menuOpen = useReactiveVar(isMenuOpen);

  return (
    <NavBarMobileStyle top={top} open={menuOpen}>
      <LogoNav
        imgSrc={
          top
            ? process.env.PUBLIC_URL + "/WhiteLogo.svg"
            : process.env.PUBLIC_URL + "/MaroonLogo.svg"
        }
        pad={pad}
        isMobile={isMobile}
      />
      <HamburgerMenu top={top} />
    </NavBarMobileStyle>
  );
};

NavBarMobile.propTypes = {
  top: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default NavBarMobile;
