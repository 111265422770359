import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NavBarDesktopStyle from "./styles/NavBarDesktopStyle";
import LogoNav from "./LogoNav";

const NavBarDesktop = ({ top, isMobile }) => (
  <NavBarDesktopStyle top={top}>
    <div>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/galleries">Gallery</Link>
      <Link to="/testimonials">Testimonials</Link>
    </div>

    <LogoNav
      imgSrc={
        top
          ? process.env.PUBLIC_URL + "/WhiteLogo.svg"
          : process.env.PUBLIC_URL + "/MaroonLogo.svg"
      }
      pad={false}
      isMobile={isMobile}
    />

    <div>
      <Link to="/contact">Contact</Link>
    </div>
  </NavBarDesktopStyle>
);

NavBarDesktop.propTypes = {
  top: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default NavBarDesktop;
