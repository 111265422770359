import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
    @font-face {
        font-family: 'FuturaPT-Light';
        src: url('../../public/fonts/FuturaPT-Light.eot');
        src: url('../../public/fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
            url('../../public/fonts/FuturaPT-Light.svg#FuturaPT-Light') format('svg'),
            url('../../public/fonts/FuturaPT-Light.ttf') format('truetype'),
            url('/fonts/FuturaPT-Light.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    box-sizing: border-box;
    font-size: 10px;
    padding: 0;
    margin: 0;
    background-color: #FFFAFA;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2;
}
a {
    text-decoration: none;
}
`;

export default GlobalStyle;
